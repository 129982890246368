<template>
  <div class="diffusion-page desktop-container bg-center m-auto base__line">
    <div class="desktop-screen relative m-auto">
      <div class="bg-mobile bg-center pt-4 bg-top pb-8">
        <div class="text-white base__line">
          <logo-icon></logo-icon>
        </div>
        <section class="mt-3 mx-auto form">
          <img
            class="score-img m-auto"
            :src="getImage($t(`diffusion.scores[${score}].image`))"
            alt
            srcset
          />
          <div class="diffusion-header mb-6">
            <div class="content encart-shadow bg-secondary m-auto mb-4 p-4 pb-5 text-white font-lato">
              <div class="h-full center-vertically">
                <div class="relative">
                  <p class="text-md text-center" v-html="$t(`diffusion.scores[${score}].description`)"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="encart versus-encart encart-shadow bg-secondary m-auto p-4 mb-6">
            <img
              class="score-img m-auto"
              src="../assets/confirm/VS@2x.png"
              alt
              srcset
            />
            <p class="text-lg text-center text-white font-latoBold" v-html="$t(`diffusion.challenge`)"></p>

            <div class="shares flex flex-wrap justify-center virals">
              <NCta
                data-cy="share-facebook-cta"
                borderRadius="30px"
                :backgroundColor="colorStyle.primary"
                share_type="facebook"
                @click.native="shareFb(); trackClick(linkNumbers.facebook)"
              >
                <template #icon>
                  <NIconFacebook color="#0D4355" />
                </template>
              </NCta>

              <NCta
                data-cy="share-messenger-cta"
                share_type="messenger"
                borderRadius="30px"
                :backgroundColor="colorStyle.primary"
                v-if="typeOfDevice === 'mobile'"
                @click.native="shareMessenger(); trackClick(linkNumbers.messenger)"
              >
                <template #icon>
                  <NIconMessenger color="#0D4355" />
                </template>
              </NCta>

              <NCta
                v-if="typeOfDevice === 'mobile'"
                data-cy="share-whatsapp-cta"
                borderRadius="30px"
                :backgroundColor="colorStyle.primary"
                share_type="whatsapp"
                @click.native="shareWhatsapp(); trackClick(linkNumbers.whatsapp)"
              >
                <template #icon>
                  <NIconWhatsapp color="#0D4355" />
                </template>
              </NCta>

              <NCta
                @click.native="updateModalConfig({ isOpen: true, modalComponent: 'DiffusionEmailsModal', modalClass: 'diffusion-email-modal' })"
                share_type="envelope"
                data-cy="share-mail-cta"
                borderRadius="30px"
                :backgroundColor="colorStyle.primary"
              >
                <template #icon>
                  <NIconMail color="#0D4355" />
                </template>
              </NCta>

            </div>
          </div>

          <div class="encart find-encart encart-shadow bg-secondary m-auto p-4">
            <img
              class="seum-img m-auto"
              src="../assets/confirm/titre_seum@2x.png"
              alt
              srcset
            />
            <img
              class="card-img m-auto"
              src="../assets/confirm/credit cards@2x.png"
              alt
              srcset
            />
            <p
              class="text-lg text-center text-white font-latoBold" 
              v-html="$t(`diffusion.find`)"
            ></p>

            <span class="table m-auto mb-3">
              <NCta
                borderRadius="30px"
                backgroundColor="#FFD400"
                textColor="#014457"
                data-cy="submit-register"
                @click.native="exitUrl(6)"
                designType="round"
                ref="form_submit_button"
              >
              <span class="text-md font-oswaldBold uppercase">{{$t('diffusion.submit')}}</span></NCta>
            </span>

          </div>
          
        </section>
      </div>
      <SiteFooter></SiteFooter>
    </div>
    <div class="gaming-bg"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isProdEnv, getDeviceType } from "../helpers";
import design from '@/validators/design'
import LogoIcon from "@/components/icons/LogoIcon";

// import svg share icon
import {
  NIconMail,
  NIconFacebook,
  NIconWhatsapp,
  NIconMessenger,
} from "@team-uep/n-icon";

export default {
  name: "diffusionPage",
  data() {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      typeOfDevice: getDeviceType(),
      // score: 0
    };
  },
  components: {
    NIconMail,
    NIconFacebook,
    NIconWhatsapp,
    NIconMessenger,
    LogoIcon
  },
  methods: {
    ...mapActions(["updateModalConfig"]),
    shareFb() {
      let title = this.$t(`diffusion.share.facebook_text_s${this.score}`)
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getShareUrl(this.idfroms.facebook))}&title=${title}`, '_blank')
    },
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName.replace(/[_-]/, '_')}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    shareTwitter() {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        this.$t("diffusion.share.twitter_text")
      )}&url=${encodeURIComponent(this.getShareUrl(this.idfroms.twitter))}`,
        "_blank"
      ); 
    },
    shareWhatsapp() {
      window.location.href = `whatsapp://send?text=Prêt(e) à relever le défi ? ${encodeURIComponent(this.getShareUrl(this.idfroms.whatsapp))}`
    },
    shareMessenger() {
      window.location.href = `fb-messenger://share/?link=${encodeURIComponent(
        this.getShareUrlWithSlug(this.idfroms.messenger)
      )}&app_id=${
        isProdEnv()
          ? this.decliConfig.facebook.appIds.prod
          : this.decliConfig.facebook.appIds.staging
      }, _self`;
    },
    getShareUrl(idfrom) {
      return `${window.location.origin}?idfrom=${idfrom}&idup=${this.idu}`;
    },
    getShareUrlWithSlug(idfrom) {
      return `${window.location.origin}/s/${idfrom}/${this.idu}`;
    },
    trackClick(linkId) {
      const oTrackData = {
        iIdU: +this.idu,
        iPageId: this.$route.meta.pageId,
        iLinkNumber: linkId,
        iVisitId: +this.visitId,
      };

      this.$requestAPI.trackPageClick(oTrackData)
        .catch(console.log);
    },
    getImage(image) {
      return require(`../assets/confirm/${image}`)
    }
  },
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      user: "user/user",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    }),

    idfroms() {
      return this.decliConfig.idfroms;
    },
    linkNumbers() {
      return this.decliConfig.linkNumbers;
    },
    score() {
      return this.user.oResponses.iQuestion_502
    }
  },
  mounted() {
    this.$mmTro.sendRtg();
  },
};
</script>

<style lang="scss" scoped>
.diffusion-page {
  line-height: 20px;
  .encart-shadow {
    box-shadow: 0px 3px 6px #0D435580;
  }
  .diffusion-header {
    .content {
      max-width: 324px;
      width: 100%;
      height: 100%;
    }
  }
  .encart {
    max-width: 340px;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
  }
  .versus-encart {
    height: 100%;
    padding-bottom: 1.5rem;
    img {
      max-width: 181px;
      width: 100%;
      height: auto;
    }
    .n-cta ::v-deep {
      margin-right: 1rem;
    }
  }
  .find-encart {
    .n-cta ::v-deep {
      width: 200px;
      padding: 12px 25px !important;
      background-color: #FFD400 !important;
      font-family: "Oswald Bold";
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .score-img {
    max-width: 360px;
  }
  .seum-img {
    max-width: 288px;
  }
  .card-img {
    max-width: 138px;
  }
}
.virals {
  max-width: 374px;
  margin: auto;
  margin-left: 1rem;
}
.n-cta ::v-deep {
  @apply mt-4;
  svg {
    background-color: #FFD400;
  }
}
</style>
